<svelte:options tag="usertabs-svelte" />
<script>
    import {
        activeUserTabIndex,
        collectionPoints,
        users,
        closeUser,
        getConnectionById,
        updateUser,
        mapFields,
        fieldsDictionary,
        fakeFields,
        getConnectionFields,
        pingTimer,
        messageSettings,
        tabName,
        globalInterval
    } from "./store";
    import {onDestroy, onMount} from "svelte";

    // let pingTimer;
    let timers = {}
    const PING_TIME = 5000;
    const ERROR_DURATION = 5000;

    $: currentUser = $users[$activeUserTabIndex]


    onMount(() => {
        console.log($users, 'my users')
        $users.forEach(async (user) => {
            if (!user.isNew && (user.status === 'PENDING' || user.status === 'INI4TIALISED')) {
                pingForResponse(user.id)
            }
        })
    })

    const addNewUser = () => {
        users.set([...$users, {
            id: $users.length + 1,
            phone: '',
            collectionPoint: $collectionPoints[0]?.code,
            error: null,
            isPending: false,
            fields: null,
            isNew: true
        }])

        messageSettings.set($collectionPoints[0]?.settings)

        activeUserTabIndex.set($users.length - 1)
    }

    let timer;
    const pingForResponse = (connectionId) => {
        timer = setInterval(async () => {
            const { data: connection } = await getConnectionById(connectionId);

            if (connection.status === 'CANCELLED') {
                clearInterval(timer);
                currentUser.isNew = false;
                currentUser.isPending = false;
                currentUser.error = null;

                return
            }

            if (connection.status === 'COMPLETE') {
                clearInterval(timer);

                const { data: fields } = await getConnectionFields(connectionId);
                currentUser.isNew = false;
                currentUser.isPending = false;
                currentUser.error = null;
                currentUser.fields = mapFields($fieldsDictionary, fields);
                const nameFields = fields?.filter((field) => field.field === 'NAME');
                const userTitle = nameFields?.length ? nameFields.map((field) => field.value).join(' ') : null;
                currentUser.userTitle = userTitle

                if (userTitle) {
                    tabName.set(userTitle)
                }


                return;
            }

            if (connection.status === 'PENDING' || connection.status === 'INITIALISED') {
                currentUser.isNew = false;
                currentUser.isPending = true;
                currentUser.fields = null;
                currentUser.error = null;
            }

            if (connection.status === 'UNKNOWN_PHONE') {
                currentUser.isNew = false;
                currentUser.isPending = false;
                currentUser.fields = null;
                currentUser.error = 'Could not find a customer with this phone number. Please, use another one';
                clearInterval(timer)
            }

        }, PING_TIME)
    }


    const onClose = (index) => {
        clearInterval(timer)
        clearInterval($globalInterval)
        closeUser(index);
        tabName.set('Customer 1');
    }
</script>

<ul class="tabs">

        <li class="tab" class:tab-active={$activeUserTabIndex === 0} on:click={() => activeUserTabIndex.set(0)}>
            <span>
                {$tabName || 'Customer 1'}
            </span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="close-btn"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                on:click={() => onClose(0)}
            >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </li>

<!--    <li class="tab">-->
<!--        <div>-->
<!--            <span class="opacity-25">-->
<!--                + Add new-->
<!--            </span>-->
<!--            <p class="coming-soon">-->
<!--                Coming soon-->
<!--            </p>-->
<!--        </div>-->
<!--    </li>-->
</ul>

<style>
    .text-xs {
        font-size: 0.75rem;
    }

    .block {
        display: block;
    }

    .coming-soon {
        padding-top: 4px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        margin: 0;
        color: #500778;
    }

    .opacity-25 {
        opacity: 25%;
    }

    .tabs {
        display: flex;
        align-items: center;
        padding: 0 0 1rem 0;
        margin: 0;
        overflow: auto;
    }

    .tab {
        font-size: 14px;
        min-width: 130px;
        padding: 1rem 1rem 0.5rem 1rem;
        height: 36px;
        border-bottom: 2px solid white;
        cursor: pointer;
        margin-right: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }

    .tab .close-btn {
        width: 12px;
        height: 12px;
        margin-left: 6px;
    }

    .tab-active, .tab:hover {
        border-bottom: 2px solid #500778;
        color: #500778
    }
</style>
