<svelte:options tag="app-svelte" />

<script>
  import UserForm from './UserForm.svelte'
  import UserTabs from './UserTabs.svelte'
  import Login from './Login.svelte'
  import Lol from './Lol.svelte'
  import Test from './Test.svelte'
  import {
    collectionPoints,
    fbAuth,
    pingTimer,
    provider,
    providerName,
    users,
  } from './store'
  let isOpen = false

  const slideLeft = () => {
    return {
      duration: 150,
      css: (t) => {
        return `
				transform: translateX(${240 - t * 240}px);
			`
      },
    }
  }

  const handleCTAClick = () => {
    isOpen = !isOpen
  }

  const logout = async () => {
    await $fbAuth.signOut()

    pingTimer.set(null)
    // provider.set(null);
    providerName.set('')
    // collectionPoints.set([]);
  }

  const close = () => {
    isOpen = false
    pingTimer.set(null)
    // provider.set(null);
    providerName.set('')
    // collectionPoints.set([]);
  }
</script>

<main>
  <div>
    <div style="display: flex; justify-content: center">
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 2048 298.95"
        width="33%"
      >
        <defs>
          <style>
            .cls-1 {
              fill: url(#linear-gradient);
            }

            .cls-2 {
              fill: #1d1d1b;
            }
          </style>
          <linearGradient
            id="linear-gradient"
            x1="0"
            y1="149.48"
            x2="298.95"
            y2="149.48"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".5" stop-color="#500778" />
            <stop offset=".71" stop-color="#680e88" />
            <stop offset="1" stop-color="#87189d" />
          </linearGradient>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <g>
              <path
                class="cls-2"
                d="M467.63,240.02c-13.75,0-26.52-2.41-38.3-7.23-11.79-4.82-22.14-11.52-31.07-20.09-8.93-8.57-15.85-18.7-20.76-30.4-4.91-11.69-7.37-24.42-7.37-38.17s2.45-26.47,7.37-38.17c4.91-11.69,11.69-21.83,20.36-30.4,8.66-8.57,18.84-15.22,30.54-19.96,11.69-4.73,24.24-7.1,37.64-7.1,9.46,0,18.79,1.34,27.99,4.02,9.19,2.68,17.54,6.43,25.05,11.25,7.5,4.82,13.57,10.27,18.21,16.34l-13.93,14.2c-5-5.18-10.63-9.64-16.88-13.39-6.25-3.75-12.82-6.7-19.69-8.84-6.88-2.14-13.71-3.21-20.49-3.21-10.54,0-20.32,1.88-29.33,5.63-9.02,3.75-16.92,8.97-23.71,15.67-6.79,6.7-12.05,14.64-15.8,23.84-3.75,9.2-5.63,19.24-5.63,30.13s1.92,20.4,5.76,29.6c3.84,9.2,9.28,17.24,16.34,24.11,7.05,6.88,15.22,12.19,24.51,15.94,9.28,3.75,19.29,5.62,30,5.62,8.39,0,16.29-1.25,23.71-3.75,7.41-2.5,13.88-6.03,19.42-10.58,5.53-4.55,9.82-9.86,12.86-15.94,3.03-6.07,4.55-12.59,4.55-19.55v-9.38l4.29,5.36h-65.89v-20.63h82.5c.18,1.43.36,3.08.54,4.96.18,1.88.31,3.75.4,5.63.09,1.88.13,3.62.13,5.22,0,11.61-2.14,22.28-6.43,32.01-4.29,9.74-10.22,18.13-17.81,25.18-7.59,7.06-16.39,12.5-26.38,16.34-10,3.84-20.89,5.76-32.68,5.76Z"
              />
              <path
                class="cls-2"
                d="M642.81,240.02c-13.93,0-26.3-3.03-37.1-9.11-10.81-6.07-19.24-14.55-25.31-25.45-6.07-10.89-9.11-23.39-9.11-37.5,0-10.89,1.69-20.76,5.09-29.6,3.39-8.84,8.17-16.56,14.33-23.17,6.16-6.6,13.53-11.74,22.1-15.4,8.57-3.66,17.85-5.49,27.86-5.49,9.46,0,18.08,1.7,25.85,5.09,7.77,3.39,14.47,8.08,20.09,14.06,5.63,5.98,9.87,13.08,12.72,21.3,2.85,8.22,4.11,17.24,3.75,27.05l-.27,9.38h-116.52l-2.95-16.88h104.2l-4.82,4.55v-6.43c-.36-6.43-2.23-12.63-5.63-18.62-3.39-5.98-8.17-10.85-14.33-14.6-6.16-3.75-13.53-5.63-22.1-5.63-10,0-18.66,2.01-25.98,6.03-7.32,4.02-12.95,9.96-16.87,17.81-3.93,7.86-5.89,17.59-5.89,29.2s2.14,20.67,6.43,28.8c4.29,8.13,10.4,14.42,18.35,18.88,7.94,4.47,17.55,6.7,28.8,6.7,6.6,0,13.17-1.11,19.69-3.35,6.52-2.23,13.7-6.65,21.56-13.26l10.45,14.46c-4.11,4.11-9.02,7.73-14.73,10.85-5.72,3.13-11.97,5.63-18.75,7.5-6.79,1.87-13.75,2.81-20.89,2.81Z"
              />
              <path
                class="cls-2"
                d="M719.42,116.81v-19.29h87.06v19.29h-87.06ZM751.02,237.34V60.82h20.09v176.52h-20.09Z"
              />
              <path
                class="cls-2"
                d="M839.15,237.34V49.84h20.36l72.06,117.06-9.64-.27,72.86-116.79h19.02v187.5h-20.89v-85.72c0-16.07.27-30.54.8-43.39.54-12.86,1.52-25.62,2.95-38.3l3.75,7.5-67.23,105.54h-14.46l-65.36-104.2,2.95-8.84c1.43,11.97,2.41,24.24,2.95,36.83.54,12.59.8,27.55.8,44.87v85.72h-20.89Z"
              />
              <path
                class="cls-2"
                d="M1084.51,298.95l27.05-65.89,1.88,19.02-70.18-154.56h22.77l47.95,107.15c1.43,2.86,2.85,6.21,4.29,10.04,1.43,3.84,2.77,7.63,4.02,11.38l-7.5-.8c1.25-3.03,2.45-6.16,3.62-9.38,1.16-3.21,2.28-6.34,3.35-9.37l42.06-109.02h23.04l-56.52,139.82-24.11,61.61h-21.7Z"
              />
              <path
                class="cls-2"
                d="M1216.03,237.34V49.84h70.72c12.86,0,24.6,2.28,35.22,6.83,10.62,4.55,19.86,11.03,27.72,19.42,7.86,8.4,13.93,18.31,18.21,29.73,4.29,11.43,6.43,24.02,6.43,37.77s-2.14,26.38-6.43,37.9c-4.29,11.52-10.31,21.43-18.08,29.73-7.77,8.3-17.01,14.73-27.72,19.29-10.72,4.55-22.5,6.83-35.36,6.83h-70.72ZM1236.92,220.74l-2.41-3.75h50.89c9.82,0,18.88-1.74,27.19-5.22,8.3-3.48,15.44-8.48,21.43-15,5.98-6.52,10.62-14.24,13.93-23.17,3.3-8.93,4.96-18.93,4.96-30s-1.65-21.07-4.96-30c-3.31-8.93-7.99-16.65-14.06-23.17-6.07-6.52-13.22-11.52-21.43-15-8.22-3.48-17.24-5.22-27.05-5.22h-51.7l3.21-3.21v153.75Z"
              />
              <path
                class="cls-2"
                d="M1468.62,240.02c-13.93,0-26.3-3.03-37.1-9.11-10.81-6.07-19.24-14.55-25.31-25.45-6.07-10.89-9.11-23.39-9.11-37.5,0-10.89,1.69-20.76,5.09-29.6,3.39-8.84,8.17-16.56,14.33-23.17,6.16-6.6,13.53-11.74,22.1-15.4,8.57-3.66,17.85-5.49,27.86-5.49,9.46,0,18.08,1.7,25.85,5.09,7.77,3.39,14.47,8.08,20.09,14.06,5.63,5.98,9.87,13.08,12.72,21.3,2.85,8.22,4.11,17.24,3.75,27.05l-.27,9.38h-116.52l-2.95-16.88h104.2l-4.82,4.55v-6.43c-.36-6.43-2.23-12.63-5.63-18.62-3.39-5.98-8.17-10.85-14.33-14.6-6.16-3.75-13.53-5.63-22.1-5.63-10,0-18.66,2.01-25.98,6.03-7.32,4.02-12.95,9.96-16.87,17.81-3.93,7.86-5.89,17.59-5.89,29.2s2.14,20.67,6.43,28.8c4.29,8.13,10.4,14.42,18.35,18.88,7.94,4.47,17.55,6.7,28.8,6.7,6.6,0,13.17-1.11,19.69-3.35,6.52-2.23,13.7-6.65,21.56-13.26l10.45,14.46c-4.11,4.11-9.02,7.73-14.73,10.85-5.72,3.13-11.97,5.63-18.75,7.5-6.79,1.87-13.75,2.81-20.89,2.81Z"
              />
              <path
                class="cls-2"
                d="M1545.23,116.81v-19.29h87.06v19.29h-87.06ZM1576.84,237.34V60.82h20.09v176.52h-20.09Z"
              />
              <path
                class="cls-2"
                d="M1710.23,240.02c-12.15,0-23.13-3.17-32.95-9.51-9.82-6.34-17.59-15-23.3-25.98-5.72-10.98-8.57-23.44-8.57-37.37s2.95-26.61,8.84-37.5c5.89-10.89,13.8-19.46,23.71-25.72,9.91-6.25,21.03-9.38,33.35-9.38,7.32,0,14.1,1.07,20.36,3.21,6.25,2.14,11.83,5.14,16.74,8.97,4.91,3.84,9.01,8.3,12.32,13.39,3.3,5.09,5.58,10.58,6.83,16.47l-5.89-2.95.8-36.16h20.09v139.82h-20.36v-34.29l5.36-3.48c-1.25,5.36-3.66,10.49-7.23,15.4-3.57,4.91-7.9,9.24-12.99,12.99-5.09,3.75-10.81,6.7-17.14,8.84-6.34,2.14-12.99,3.21-19.96,3.21ZM1714.79,220.74c9.28,0,17.59-2.28,24.91-6.83,7.32-4.55,13.08-10.89,17.28-19.02,4.19-8.12,6.29-17.37,6.29-27.72s-2.1-19.55-6.29-27.59c-4.2-8.04-9.96-14.37-17.28-19.02-7.32-4.64-15.63-6.96-24.91-6.96s-17.81,2.32-25.05,6.96c-7.23,4.65-12.95,10.98-17.14,19.02-4.2,8.04-6.29,17.24-6.29,27.59s2.05,19.33,6.16,27.46c4.11,8.13,9.82,14.51,17.14,19.15,7.32,4.65,15.71,6.96,25.18,6.96Z"
              />
              <path
                class="cls-2"
                d="M1836.93,67.25c-5,0-8.8-1.29-11.38-3.88-2.59-2.59-3.88-6.11-3.88-10.58s1.34-8.04,4.02-10.71c2.68-2.68,6.43-4.02,11.25-4.02s8.57,1.3,11.25,3.88c2.68,2.59,4.02,6.21,4.02,10.85,0,4.29-1.34,7.77-4.02,10.45-2.68,2.68-6.43,4.02-11.25,4.02ZM1827.02,237.34V97.52h20.09v139.82h-20.09Z"
              />
              <path
                class="cls-2"
                d="M1891.57,237.34V39.13h20.09v198.22h-20.09Z"
              />
              <path
                class="cls-2"
                d="M1997.11,240.02c-11.97,0-22.68-2.23-32.14-6.7-9.47-4.46-16.97-10.35-22.5-17.68l14.2-12.32c5.18,6.25,11.29,10.98,18.35,14.2,7.05,3.21,14.95,4.82,23.71,4.82,4.46,0,8.48-.49,12.05-1.47,3.57-.98,6.7-2.45,9.38-4.42,2.68-1.96,4.73-4.29,6.16-6.96,1.43-2.68,2.14-5.63,2.14-8.84,0-5.89-2.59-10.8-7.77-14.73-2.32-1.61-5.63-3.26-9.91-4.96-4.29-1.69-9.47-3.35-15.54-4.96-10-2.85-18.31-5.76-24.91-8.71-6.61-2.95-11.79-6.38-15.54-10.31-2.68-3.21-4.69-6.7-6.03-10.45-1.34-3.75-2.01-7.86-2.01-12.32,0-5.71,1.21-10.98,3.62-15.8,2.41-4.82,5.8-9.06,10.18-12.72,4.37-3.66,9.55-6.43,15.54-8.3,5.98-1.88,12.46-2.81,19.42-2.81,6.6,0,13.12.9,19.55,2.68,6.43,1.79,12.41,4.38,17.95,7.77,5.53,3.39,10.27,7.41,14.2,12.05l-12.32,13.39c-3.39-3.57-7.14-6.78-11.25-9.64-4.11-2.85-8.44-5.09-12.99-6.7-4.55-1.61-9.24-2.41-14.06-2.41-4.29,0-8.26.54-11.92,1.61-3.66,1.07-6.79,2.54-9.38,4.42-2.59,1.88-4.55,4.15-5.89,6.83-1.34,2.68-2.01,5.63-2.01,8.84,0,2.68.54,5.09,1.61,7.23,1.07,2.14,2.59,4.02,4.55,5.63,2.5,1.97,6.03,3.8,10.58,5.49,4.55,1.7,10.22,3.44,17.01,5.22,7.86,2.14,14.51,4.33,19.96,6.56,5.44,2.23,9.95,4.87,13.53,7.9,4.82,3.57,8.26,7.68,10.31,12.32,2.05,4.65,3.08,9.91,3.08,15.8,0,7.86-2.19,14.83-6.56,20.89-4.38,6.07-10.41,10.85-18.08,14.33-7.68,3.48-16.43,5.22-26.25,5.22Z"
              />
            </g>
            <path
              class="cls-1"
              d="M274.12,24.85C252.53,1.33,201.01,1,149.48,0,97.96,1,46.45,1.33,24.85,24.85,1.33,46.45,1,97.96,0,149.48c1,51.53,1.33,103.05,24.85,124.64,21.59,23.5,73.11,23.85,124.62,24.83,51.53-.98,103.05-1.33,124.64-24.83,23.5-21.59,23.85-73.11,24.83-124.64-.98-51.51-1.33-103.03-24.83-124.62ZM254.7,151.04v62.51l-80.74-80.78v114.33H44.32l49.57-49.55-49.65-49.67v-17.98l58.65,58.63,8.98,9.02-9,8.98-27.85,27.85h86.21v-86.21l-27.11,27.13-9,8.98-9-9L44.23,103.29v-18.01l80.78,80.9V51.86h129.69l-49.57,49.59,49.57,49.59Z"
            />
          </g>
        </g>
      </svg>
    </div>
    <p style="text-align: center; font-weight: 500">
      GetMyDetails Call centre widget demo
    </p>
  </div>
  <button class="cta-button" on:click={handleCTAClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      data-name="Layer 2"
      viewBox="0 0 145.78 145.78"
      width="50"
    >
      <rect x="10%" y="10%" width="80%" height="80%" fill="white" />
      <defs>
        <style>
          .cls-1 {
            fill: url(#linear-gradient);
          }
        </style>
        <linearGradient
          id="linear-gradient"
          x1="0"
          y1="72.89"
          x2="145.78"
          y2="72.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".5" stop-color="#500778" />
          <stop offset=".71" stop-color="#680e88" />
          <stop offset="1" stop-color="#87189d" />
        </linearGradient>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1" class="back">
        <path
          class="cls-1"
          d="M133.66,12.11C123.13.65,98.01.48,72.89,0,47.76.48,22.64.65,12.11,12.11.65,22.64.48,47.77,0,72.89c.48,25.12.65,50.25,12.11,60.78,10.53,11.46,35.65,11.63,60.78,12.11,25.12-.48,50.24-.65,60.77-12.11,11.47-10.53,11.63-35.66,12.12-60.78-.49-25.12-.65-50.25-12.12-60.78ZM124.93,73.67v30.9l-39.94-39.94v56.54H20.87l24.52-24.51-24.55-24.56v-8.9l29,29.01,4.44,4.45-4.45,4.44-13.77,13.78h42.64v-42.64l-17.87,17.87-4.45-4.46L20.84,50.05v-8.9l39.95,40.02V24.61h64.14l-24.52,24.53,24.52,24.53Z"
        />
      </g>
    </svg>
  </button>
  {#if isOpen}
    <div class="content" transition:slideLeft>
      <div class="header">
        <div class="app-title">
          {#if $providerName}
            <svg
              width="18"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          {/if}
          {#if $providerName}
            <div class="name-with-login" on:click={logout}>
              {$providerName}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </div>
          {:else}
            <span>GetMyDetails call centre</span>
          {/if}
        </div>

        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          class="close-btn"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          on:click={close}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg> -->
      </div>
      <div class="main-content">
        <test-svelte />
      </div>
    </div>
  {/if}
</main>

<style>
  * {
    box-sizing: border-box;
  }

  .name-with-login {
    display: flex;
    align-items: center;
  }

  .name-with-login svg {
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .app-title {
    display: flex;
    flex: 1;
    align-items: center;
    font-weight: 500;
    padding: 0.5rem 0;
  }

  .app-title svg {
    margin-right: 8px;
  }

  .cta-button {
    position: fixed;
    right: -0.5rem;
    bottom: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    width: max-content;
    box-shadow: 0px 0px 8px 1px #00000040;
    cursor: pointer;
  }
  .cta-button:hover {
    transition: all 0.4s;
    transform: scale(1.08);
  }

  main {
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  .content {
    overflow: auto;
    width: 400px;
    background: white;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    box-shadow: 0px 0px 8px 1px #00000040;
  }

  .main-content {
    padding: 0 1rem;
  }
  /* .input {
        display: block;
        width: 100%;
        height: 48px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 8px;
        border: 1px solid gray;
    }  */

  .header {
    background: #500778;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    color: white;
  }

  .header span {
    display: block;
    flex: 1;
    color: white;
    font-size: 20px;
  }

  .close-btn {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
