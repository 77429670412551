<svelte:options tag="userform-svelte" />

<script>
  import {
    activeUserTabIndex,
    collectionPoints,
    createNewConnection,
    deleteUser,
    fieldsDictionary,
    messageSettings,
    getConnectionById,
    getConnectionFields,
    mapFields,
    token,
    updateUser,
    users,
    tabName,
    globalInterval,
    provider,
  } from './store'
  import { v4 as uuid } from 'uuid'
  import dayjs from 'dayjs'
  import { onDestroy, onMount } from 'svelte'
  import axios from 'axios'

  let isRequestSending = false
  let timer = null
  const PING_TIME = 1000
  const ERROR_DURATION = 5000
  const API_URL = 'https://europe-west2-synq-functions.cloudfunctions.net'

  $: currentUser = $users[$activeUserTabIndex]

  let notification_title = ''
  let notification_body = ''
  let call_centre_greeting = ''
  let agent_name = ''
  let agent_from_number = ''
  let reference_id_allowed = false
  let reference_id_label = ''
  let next_template = ''
  let call_centre_cancel_button = ''
  let call_centre_submit_button = ''
  let selectedOption = $collectionPoints[0].code
  let selectedName = $collectionPoints[0].name
  let selectedOptionIsNextTemplate = false

  let auth_token = ''

  let isNumberApproved = false

  token.subscribe((value) => {
    auth_token = value
  })

  let globalSettings = {}
  messageSettings.subscribe((settings) => {
    globalSettings = settings
    notification_title = settings.notification_title
    notification_body = settings.notification_body
    call_centre_greeting = settings.call_centre_greeting
    agent_name = settings.agent_name
    agent_from_number = settings.agent_from_number
    reference_id_allowed = settings.reference_id
    reference_id_label = settings.reference_id_label
    call_centre_cancel_button = settings.call_centre_cancel_button
    call_centre_submit_button = settings.call_centre_submit_button
  })

  const formatTime = (date) => {
    return dayjs(date).format('D MMM YYYY')
  }

  let isSecurityChecked = false

  const pingForResponse = (connectionId) => {
    timer = setInterval(async () => {
      const { data: connection } = await getConnectionById(connectionId)

      if (connection.status === 'COMPLETE') {
        const { data: fields } = await getConnectionFields(connectionId)
        currentUser.isNew = false
        currentUser.isPending = false
        currentUser.fields = mapFields($fieldsDictionary, fields)
        const nameFields = fields?.filter((field) => field.field === 'NAME')
        const userTitle = nameFields?.length
          ? nameFields.map((field) => field.value).join(' ')
          : null
        currentUser.userTitle = userTitle

        if (currentUser.fields.length === 0) { 
          isSecurityChecked = true
        }

        if (userTitle) {
          tabName.set(userTitle)
        }

        if (currentUser.collectionPoint) {
          next_template = $collectionPoints.find(
            (point) => String(point.id) === String(currentUser.collectionPoint)
          )?.settings?.next_template

          if (next_template) {
            selectedOption = next_template.code
            selectedName = next_template.name
            selectedOptionIsNextTemplate = true
            currentUser.collectionPoint = selectedOption
          }
        }

        clearInterval(timer)
        return
      }

      if (connection.status === 'CANCELLED') {
        currentUser.isNew = false
        currentUser.isPending = false
        currentUser.error = null
        currentUser.isCancelled = true
        clearInterval(timer)

        return
      }

      if (connection.status === 'UNKNOWN_PHONE') {
        currentUser.isPending = false
        currentUser.isNew = false
        currentUser.error =
          'Could not find a customer with this phone number. Please, use another one'
        clearInterval(timer)
      }
    }, PING_TIME)
    globalInterval.set(timer)
  }

  const handleSendRequest = async () => {
    clearInterval(timer)
    tabName.set('Customer 1')

    selectedOptionIsNextTemplate = false

    if (currentUser.isPending) {
      isRequestSending = true
      await deleteUser(currentUser.id, $token)
      currentUser.error = null
      currentUser.isNew = true
      currentUser.fields = null
      currentUser.isPending = false
      currentUser.isCancelled = false
      isRequestSending = false
      return
    }
    if (!currentUser.isNew) {
      isRequestSending = true
      // const reference_id = currentUser.reference_id
      await deleteUser(currentUser.id, $token)

      if (!verified) {
        await verifyNumber()
      }
      const newUserId = uuid()
      await createNewConnection(
        { ...currentUser, settings: globalSettings },
        newUserId
      )

      currentUser.fields = null
      currentUser.error = null
      currentUser.isCancelled = false

      currentUser.isPending = true
      currentUser.id = newUserId
      isRequestSending = false

      pingForResponse(currentUser.id)
    } else {
      // await deleteUser(currentUser.id, $token)
      if (!verified) {
        await verifyNumber()
      }
      isRequestSending = true
      const newUserId = uuid()
      if (localStorage.getItem('synq_widget_agent_name')) {
        await createNewConnection(
          { ...currentUser, settings: globalSettings },
          newUserId
        )
      } else {
        await createNewConnection({ ...currentUser }, newUserId)
      }

      currentUser.isNew = false
      currentUser.error = null
      currentUser.fields = null
      currentUser.isCancelled = false

      currentUser.isPending = true
      currentUser.id = newUserId
      isRequestSending = false

      pingForResponse(newUserId)
    }
  }

  const handleSendRequestNextTemplate = () => {
    handleCollectionPointSelect({ target: { value: next_template.code } })
    handleSendRequest()
  }

  const handleCollectionPointSelect = (e) => {
    currentUser.collectionPoint = e.target.value
    selectedOptionIsNextTemplate = false
    selectedOption = currentUser.collectionPoint
    console.log('ddd', currentUser.collectionPoint)

    const settings = $collectionPoints.find(
      (point) => String(point.id) === String(e.target.value)
    )?.settings

    if (settings) {
      messageSettings.set(settings)
    }

    updateUser($activeUserTabIndex, currentUser)

    messageSettings.subscribe((settings) => {
      reference_id_allowed = settings.reference_id
      reference_id_label = settings.reference_id_label
      call_centre_cancel_button = settings.call_centre_cancel_button
      call_centre_submit_button = settings.call_centre_submit_button
    })
  }

  const handleCustomerPhoneChange = (e) => {
    let newValue = e.target.value

    verified = false
    isNumberApproved = false

    // phone number validation in format +xxxxxxxxxxxx
    if (newValue.length > 0 && newValue[0] !== '+') {
      newValue = '+' + newValue
    }
    if (newValue.length > 13) {
      newValue = newValue.slice(0, 13)
      e.target.value = newValue
    }

    // remove all non-digit characters except '+'
    newValue = newValue.replace(/[^\d+]/g, '')
    e.target.value = newValue

    currentUser.phone = newValue
    updateUser($activeUserTabIndex, currentUser)
  }

  const handleReferenceIdChange = (e) => {
    currentUser.reference_id = e?.target?.value
    updateUser($activeUserTabIndex, currentUser)
  }

  const slideFadeLeft = () => {
    return {
      duration: 100,
      css: (t) => {
        return `
                opacity: ${t};
				transform: translateX(${240 - t * 240}px);
			`
      },
    }
  }

  const copyToClipboard = async (value) => {
    await navigator.clipboard.writeText(value)
  }

  const copyAllDataToClipboard = async () => {
    const data = currentUser.fields
      .map((cUfield) =>
        cUfield.data
          .map((fieldData) =>
            fieldData.fields
              .map(
                (field) =>
                  `${fieldData.name !== 'DEFAULT' ? fieldData.name + ' ' : ''}${
                    field.field_definition
                  }: ${field.value}`
              )
              .join()
          )
          .join(',\r\n')
      )
      .join(',\r\n')
    await navigator.clipboard.writeText(data)
  }

  const { NEXT_PUBLIC_IS_EMBEDDED_SCRIPT } = __myapp

  const isEmbeddedScript = NEXT_PUBLIC_IS_EMBEDDED_SCRIPT === 'true'

  let settings = {}
  let isSettingsOpen = false

  const handleSettingsClick = () => {
    isSettingsOpen = true
  }

  const onSettingsClose = () => {
    isSettingsOpen = false

    notification_title = globalSettings.notification_title
    notification_body = globalSettings.notification_body
    call_centre_greeting = globalSettings.call_centre_greeting
    agent_name = globalSettings.agent_name
    agent_from_number = globalSettings.agent_from_number
  }

  const onSettingsSave = () => {
    if (
      !notification_title ||
      !notification_body ||
      !call_centre_greeting ||
      !agent_name ||
      !agent_from_number
    ) {
      return
    }

    messageSettings.set({
      notification_title,
      notification_body,
      call_centre_greeting,
      agent_name,
      agent_from_number,
    })
    isSettingsOpen = false
  }

  let verifying = false
  let verified = false

  const verifyNumber = async () => {
    verifying = true
    verified = false
    try {
      await axios.get(
        `${API_URL}/app/customer_connections/check_number/${currentUser.phone}`,
        {
          headers: {
            authorization: $token,
          },
        }
      )
      isNumberApproved = true
      verified = true
    } catch (e) {
      verified = true
      isNumberApproved = false
    }
    verifying = false
  }

  onMount(() => {
    if (localStorage.getItem('synq_widget_agent_name')) {
      globalSettings = {
        notification_title: localStorage.getItem(
          'synq_widget_notification_title'
        ),
        notification_body: localStorage.getItem(
          'synq_widget_notification_body'
        ),
        call_centre_greeting: localStorage.getItem(
          'synq_widget_call_centre_greeting'
        ),
        agent_name: localStorage.getItem('synq_widget_agent_name'),
        agent_from_number: localStorage.getItem(
          'synq_widget_agent_from_number'
        ),
      }
    }

    if (currentUser.collectionPoint) {
      next_template = $collectionPoints.find(
        (point) => String(point.id) === String(currentUser.collectionPoint)
      )?.settings?.next_template
    }

    currentUser.collectionPoint = selectedOption
  })
</script>

<section>
  {#if isSettingsOpen}
    <div>
      <div class="settings-header flex justify-between">
        <h1 class="settings-title">Message settings</h1>
        <div class="close-settings-btn" on:click={onSettingsClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      {#if $provider?.permissions?.allow_agent_to_set_notification_title}
        <div class="form-group">
          <label for="notification_title" class="label"
            >Notification title</label
          >
          <input
            id="notification_title"
            type="text"
            class="input"
            name="notification_title"
            placeholder="Enter the title"
            bind:value={notification_title}
          />
        </div>
      {/if}
      {#if $provider?.permissions?.allow_agent_to_set_notification_body}
        <div class="form-group">
          <label for="notification_body" class="label">Notification body</label>
          <input
            id="notification_body"
            type="text"
            class="input"
            name="notification_body"
            placeholder="Enter the body"
            bind:value={notification_body}
          />
        </div>
      {/if}
      {#if $provider?.permissions?.allow_agent_to_set_greeting}
        <div class="form-group">
          <label for="call_centre_greeting" class="label">Greeting</label>
          <input
            id="call_centre_greeting"
            type="text"
            class="input"
            name="call_centre_greeting"
            placeholder="Enter the greeting"
            bind:value={call_centre_greeting}
          />
        </div>
      {/if}
      {#if $provider?.permissions?.allow_agent_to_set_name}
        <div class="form-group">
          <label for="agent_name" class="label">Agent name</label>
          <input
            id="agent_name"
            type="text"
            class="input"
            name="agent_name"
            placeholder="Enter the name"
            bind:value={agent_name}
          />
        </div>
      {/if}
      {#if $provider?.permissions?.allow_agent_to_set_number}
        <div class="form-group">
          <label for="agent_from_number" class="label">Agent number</label>
          <input
            id="agent_from_number"
            type="text"
            class="input"
            name="agent_from_number"
            placeholder="Enter the number"
            bind:value={agent_from_number}
          />
        </div>
      {/if}
      <button class="submit-button" on:click={onSettingsSave}> Save </button>
    </div>
  {:else}
    <div>
      <div class="form-group">
        <label for="customer-phone" class="label">Customer phone number</label>
        <div class="flex-in-row">
          <input
            id="customer-phone"
            type="text"
            class="input"
            name="customer-phone"
            placeholder="+xxxxxxxxxxxx"
            value={currentUser.phone}
            on:input={handleCustomerPhoneChange}
          />
          <button
            class="submit-button"
            disabled={isNumberApproved}
            on:click={verifyNumber}
          >
            {verifying
              ? 'Verifying'
              : !verified
              ? isRequestSending
                ? 'Please, wait'
                : 'Check'
              : 'Checked'}
          </button>
        </div>
        {#if !verifying}
          {#if isNumberApproved}
            <p class="phone-desc">Phone number belongs to verified GetMyDetails user</p>
          {/if}
          {#if verified && !isNumberApproved}
            <p class="phone-desc phone-desc-error">
              Number does not belong to a GetMyDetails user
            </p>
          {/if}
        {/if}
      </div>
      <div class="form-group">
        <label for="collection-point" class="label">Collection point</label>
        <select
          bind:value={selectedOption}
          id="collection-point"
          class="input"
          name="collection-point"
          on:change={handleCollectionPointSelect}
        >
          {#each $collectionPoints as point}
            <option
              selected={currentUser?.collectionPoint === point.id}
              value={point.id}>{point.name}</option
            >
          {/each}
        </select>
        {#if selectedOptionIsNextTemplate}
          <p class="phone-desc">
            Next collection point {next_template.name} has been selected
          </p>
        {/if}
      </div>

      {#if reference_id_allowed}
        <div class="form-group">
          <label for="reference-id" class="label">
            {reference_id_label || 'Call Reference ID'}</label
          >
          <input
            id="reference-id"
            type="text"
            class="input"
            name="customer-phone"
            placeholder={reference_id_label
              ? 'Enter the ' + reference_id_label
              : 'Enter the reference ID'}
            value={currentUser.reference_id || ''}
            on:input={handleReferenceIdChange}
          />
        </div>
      {/if}
      {#if currentUser.isCancelled}
        <div class="waiting-container orange">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <p>The request was cancelled by receiver</p>
        </div>
      {/if}
      {#if isSecurityChecked && currentUser?.fields && currentUser.fields.length <= 0}
        <div
          class="verification-box"
          on:click={() => {
            console.log('button')
            isSecurityChecked = false
          }}
        >
          <p>The customer verified the request</p>
          <div class="close-settings-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
      {/if}
      <!--{#if !currentUser?.fields}-->
      <div class="btn-wrap flex">
        {#if !(isRequestSending || currentUser?.isPending) && Object.values($provider?.permissions || {}).filter((val) => val).length > 0}
          <button class="settings-button" on:click={handleSettingsClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </button>
        {/if}

        <button
          class="submit-button"
          class:red={currentUser?.isPending}
          disabled={!currentUser?.phone}
          on:click={handleSendRequest}
        >
          {isRequestSending
            ? 'Please, wait'
            : currentUser?.isPending
            ? call_centre_cancel_button || 'Cancel request'
            : call_centre_submit_button || 'Send request'}
        </button>
      </div>
      <!--{/if}-->
      {#if currentUser?.isPending}
        <div class="waiting-container orange">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <p>
            The request was successfully sent. Waiting for the customer to share
            the information
          </p>
        </div>
      {:else if currentUser?.error}
        <div class="waiting-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <p>
            {currentUser?.error}
          </p>
        </div>
      {/if}

      {#if currentUser?.fields?.length}
        <div class="waiting-container pt-0">
          <div class="customer-info">
            {#each currentUser.fields as field, idx}
              <div class="field-group">
                <div class="field-name">
                  {field.name}
                </div>
                <div>
                  {#each field.data as item}
                    <div class="field pl-4 pb-2">
                      {#if item.name !== 'DEFAULT'}
                        <div class="text-xs bold underline mb-4 capitalize">
                          {item.name}
                        </div>
                      {/if}
                      {#each item.fields as field}
                        <div class="field with-copy">
                          <div>
                            <span class="text-xs"
                              >{field.field_definition}
                              :
                            </span>
                            <span class="font-semibold"
                              >{field.value
                                ? field.field_definition.includes('date')
                                  ? formatTime(field.value)
                                  : field.value
                                : '-'}</span
                            >
                          </div>
                          {#if field.value}
                            <svg
                              on:click={() => copyToClipboard(field.value)}
                              xmlns="http://www.w3.org/2000/svg"
                              style="width: 16px; height: 16px"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                              />
                            </svg>
                          {/if}
                        </div>
                      {/each}
                    </div>
                  {/each}
                </div>
                {#if idx !== currentUser.fields?.length - 1}
                  <hr />
                {/if}
              </div>
            {/each}
          </div>
          <div class="btn-wrap flex btn-wrapper">
            <button
              class="submit-button px-4"
              on:click={copyAllDataToClipboard}
            >
              Copy all fields
            </button>
          </div>
        </div>
      {/if}
    </div>
  {/if}
</section>

<style>
  .underline {
    text-decoration: underline;
  }

  .mb-4 {
    margin-bottom: 8px;
  }

  .font-semibold {
    font-weight: 500;
  }

  .text-xs {
    font-size: 12px;
  }

  .bold {
    font-weight: 500;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .flex {
    display: flex;
  }

  .settings-button {
    border: none;
    background: #eeeeee;
    border-radius: 12px;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
  }

  .settings-header {
    margin-bottom: 16px;
  }

  .settings-button svg {
    width: 24px;
    height: 24px;
  }

  .settings-button svg path {
    /*stroke: #500778;*/
  }

  .settings-title {
    margin: 0;
  }

  .justify-between {
    justify-content: space-between;
  }

  .settings-subtitle {
    margin: 0;
    color: darkgray;
  }

  .close-settings-btn {
    cursor: pointer !important;
  }

  .close-settings-btn svg {
    width: 24px;
    height: 24px;
  }

  hr {
    height: 1px;
    background: lightgray;
    border: none;
  }

  .pl-4 {
    padding-left: 0.75rem;
  }

  .pb-2 {
    padding-bottom: 0.25rem;
  }

  .customer-info {
    text-align: left;
    width: 100%;
    margin-top: 12px;
    color: black;
  }

  .with-copy {
    display: flex;
    align-items: center;
  }

  .with-copy svg {
    margin-left: 8px;
    cursor: pointer;
  }

  .field {
    margin-bottom: 12px;
  }

  .field-group {
    margin-bottom: 22px;
  }

  .field-name {
    color: #500778;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    border: 1px solid gray;
    letter-spacing: 0.5pt;
    font-size: 14px;
  }

  .input::placeholder {
    font-size: 14px;
    letter-spacing: 0.5pt;
  }

  .label {
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .btn-wrap {
    padding-top: 1rem;
  }

  .submit-button {
    display: block;
    width: 100%;
    height: 48px;
    color: white;
    background: #500778;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    transition: all 0.2s;
  }

  .red {
    background: #b42e2e;
  }

  .close-settings-btn,
  .submit-button:hover,
  .settings-button:hover {
    opacity: 0.75;
    transform: scale(1.02);
  }

  .close-settings-btn,
  .submit-button:active,
  .settings-button:active {
    transform: scale(0.98);
  }

  .close-settings-btn,
  .submit-button:disabled .settings-button:disabled {
    opacity: 50% !important;
    cursor: default !important;
    pointer-events: none;
  }

  .waiting-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .orange p {
    color: gray;
  }

  .orange svg path {
    color: gray;
  }

  .response-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(21 128 61);
  }

  .response-container .text {
    font-size: 1.1rem;
    margin-top: 1rem;
    text-align: center;
    color: rgb(21 128 61);
  }

  .response-container svg {
    width: 32px;
    height: 32px;
  }

  .waiting-container p {
    padding: 0 0.5rem;
    font-size: 0.85rem;
    margin-top: 1rem;
    text-align: center;
  }

  .waiting-container svg {
    width: 32px;
    height: 32px;
  }

  .pt-0 {
    padding-top: 0;
  }

  .verification-box {
    margin-top: 12px;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .verification-box p {
    margin: 0;
    color: gray;
  }

  .mt-4 {
    margin-top: 16px;
  }

  .btn-wrapper {
    width: 100%;
    column-gap: 12px;
  }

  .flex-in-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
  }

  .phone-desc {
    margin: 4px 0 0;
    color: gray;
    font-size: 12px;
  }

  .phone-desc-error {
    color: #b42e2e;
  }
</style>
